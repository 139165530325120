.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 120vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.mbimg{
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
}
.aventus_bimg{
  background-image: url(../../../public/aventus.png);
}
.pb_bimg{
  background-image: url(../../../public/pb_bimg.png);
}
.os_bimg{
  background-image: url(../../../public/os_bimg.png);
}
.j1_bimg{
  background-image: url(../../../public/j1.png);
}
.j2_bimg{
  background-image: url(../../../public/j2.png);
}
.j3_bimg{
  background-image: url(../../../public/j3.png);
}
.j4_bimg{
  background-image: url(../../../public/j4.png);
}
.j5_bimg{
  background-image: url(../../../public/j5.png);
}
.j6_bimg{
  background-image: url(../../../public/j6.png);
}
.j7_bimg{
  background-image: url(../../../public/j7.png);
}
.j8_bimg{
  background-image: url(../../../public/j8.png);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}