.footer {
    display: flex;
    width: 100%;
    height: 100vh;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
    color: white;
    text-align: center;
    flex-direction: column;
    justify-content: space-evenly;
}

.footer-text-div {
    position: relative;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.footer-contact {
    font-size: small;
}

.footer-email {
    font-size: xx-large;
}

.footer-draw-line-top {
    /* position: absolute;  */
    left: 50%;
    top: 50px;
    right: auto;
    bottom: 0px;
    overflow: hidden;
    width: 1px;
    height: 144px;
    background-color: rgba(255, 255, 255, 0.974);
    opacity: 0.1;
}

.footer-draw-line-bottom {
    left: 50%;
    right: auto;
    overflow: hidden;
    width: 1px;
    height: 170px;
    background-color: rgba(255, 255, 255, 0.974);
    opacity: 0;
}


.show {
    opacity: 1;
    transition: all 3.5s;
}


@media only screen and (max-width: 640px) {
    .footer-contact {
        font-size: xx-small;
    }

    .footer-email {
        font-size: medium;
    }
}