.timebody{
    background-color: "none";
    padding: 50px 0;
    background-position: 50% 50%;
    background-attachment: fixed,scroll;
    overflow-x: hidden;
}
.timeline_head{
    color: rgb(255, 255, 255);
    font-weight: 600;
    font-size: 40px;
    text-align: center;
    margin-bottom: 40px;
    letter-spacing: 4px;
}
.timeline_img{
    position: absolute;
    right: 10px;
    margin: 10px;
}
.timeline_img img{
    height: 33px;
}
.maintimeline{
    position: relative;
    max-width: 1200px;
    margin: auto;
    z-index: 0;
}

.maincontainer{
    padding: 10px 50px;
    position: relative;
    width: 50%;
    box-sizing: border-box;
}

.text-box{
    display: flex;
    flex-direction: column;
    padding: 10px;
    text-align: justify;
    background:rgb(83, 85, 83);
    position: relative;
    border-radius: 4px;
    font-size: 16px;
}

.left-container{
    left:0;
}

.right-container{
    left:50%;
}


.maincontainer img{
    position: absolute;
    z-index:10 ;
}

.timelineEventImage{
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    right: -20px;
    top: 32px;
    z-index: 5;
}

.timelineimgdiv{
    position: absolute;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    right: -18px;
    top: 32px;
    z-index: 2;
    background-color:rgb(5, 210, 36);
    border: solid 5px #101010;
}
.left-container .timelineimgdiv{
    box-shadow: 0 0 0 3px rgb(2, 149, 29),inset 0 2px 0 rgb(29, 126, 12),0 3px 0 4px rgb(20, 73, 1);
}
.right-container .timelineimgdiv{
left: -20px;
background-color:rgb(5, 210, 36);
border: solid 5px #101010;
box-shadow: 0 0 0 3px rgb(2, 149, 29),inset 0 2px 0 rgb(29, 126, 12),0 3px 0 4px rgb(20, 73, 1);
}

.maintimeline::after{
    content: '';
    position: absolute;
    width: 5px;
    height: 100%;
    background-color: rgb(2, 149, 29);
    top:0;
    left:50%;
    margin-left: -3px;
    z-index:1;
}

.text-box h2{
    font-weight: 600;
}

.text-box small{
    display: inline-block;
    margin-bottom: 15px;
}

.left-container-arrow{
    height: 0;
    width: 0;
    position: absolute;
    top: 28px;
    z-index: 1;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid green;
    right: -12px;
}

.right-container-arrow{
    height: 0;
    width: 0;
    position: absolute;
    top: 28px;
    z-index: 1;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid rgb(83, 85, 83);
    left: -12px;
}

@media screen and (max-width: 600px){
    
    .maintimeline{
        margin:50px auto;
    }

    .maintimeline::after{
        left:31px;
    }
    .maincontainer{
        width: 100%;
        padding-left: 80px;
        padding-right: 25px;
    }

    .text-box{
        font-size: 18px;
    }

    .text-box small{
        margin-bottom: 10px;
    }

    .right-container{
        left: 0;
    }

    .left-container .timelineimgdiv,.right-container .timelineimgdiv{
        left: 15px;
        height: 30px;
        width: 30px;
    }

    .right-container-arrow{
        border-right:15px solid rgb(83, 85, 83);
        border-left: 0;
        left: -12px;
    }
    .left-container-arrow{
        border-right:15px solid green;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-left: 0;
        left: -12px;
    }
}

.lt{
    background-color: green;
    border-bottom: solid 6px rgba(12, 51, 1, 0.853);
}
.rt{
    border-bottom: solid 6px rgb(2, 149, 29);
}

@media(max-width:460px){
    .timebody{
        background-color: "none";
    }
    .text-box{
        font-size: 14px;
    }
    .timeline_img img{
        height: 28px;
    }
    .timeline_head{
        display: inline-block;
        text-align: left;
        margin-left: 20px;
        font-size: 28px;
        color: #fff;
        border-bottom:solid 2px rgb(2, 149, 29);
        margin-bottom: 0;
    }
}