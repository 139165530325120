.image-slider {
  width: 100%;
  margin: auto;
  text-align: center;
}

.image-slider .slick-dots {
  bottom: -30px;
}

.image-slider img {
  max-width: 97%;
  height: auto;
  max-height: 50vh;
  margin: auto;
}

.slick-arrow .slick-next{
  width: 400px;
}

@media only screen and (max-width: 640px) {
  .image-slider img {
    max-height: 30vh; /* Reduce the maximum height of the images on smaller screens */
  }
}

@media only screen and (max-width: 480px) {
  .image-slider img {
    max-height: 30vh; /* Further reduce the maximum height for even smaller screens */
  }
}