.navbar {
    display: flex;
    color: rgb(255, 255, 255);
    /* height: 92vh; */
    border: 0.1px solid rgb(64, 64, 64);
    background-color: rgba(16, 16, 16, 0.493);
    border-radius: 20px;
    position: fixed;
    width: 97%;
    border-left: none;
    border-right: none;
    border-top: none;
    backdrop-filter: blur(5px);
    z-index: 99;
}



.topnav {
    background-color: rgba(16, 16, 16, 0.493);
    border: 0.1px solid rgba(255, 255, 255, 0.2);
    border-radius: 20px 20px 7px 7px;
    width: 100%;
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    justify-content: space-between;
}

.navitems {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 150%;
    justify-content: space-evenly;
}


.navele {
    margin: 0 0.5rem 0 0.5rem;
    text-align: center;
    position: relative;
    width: auto;
    /* padding: 0 .5rem 0 .5rem; */
}

.navele:hover {
    color: black;
    transition: all 0.3s;
}

.navele::before {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.974);
    color: black;
    transform: scaleX(0);
    /* transform: translateX(-100px); */
    transform-origin: 0 50%;
    transition-duration: 0.3s;
}

.navelet {
    position: relative;
    z-index: 5;
}

.navele:hover {
    color: black;
}

.navele:hover:before {
    transform: scaleX(1);
}


.logo {
    height: 4rem;
    width: 10%;

}

.logo a {
    display: flex;
    align-items: center;
    height: 100%;
}

.logo a img {
    height: 100%;
    filter: grayscale(1);
    filter: saturate(2.2);
}

.logotext {
    margin: 0 1rem;
    font-size: xx-large;
    font-weight: bold;
    color: white;
}

@media only screen and (max-width: 1024px) {

    /* .body{
        overflow: hidden;
    } */
    .navitems {
        display: none;
    }

    .navbar {
        /* height: 95vh; */
        margin: 1rem;
        width: 96%;
    }

}

@media only screen and (max-width: 640px) {

    /* .body{
        overflow: hidden;
    } */
    .navitems {
        display: none;
    }

    .navbar {
        display: flex;
        color: rgb(255, 255, 255);
        /* height: 97vh; */
        width: 96%;
        /* z-index: -10; */
        margin: 0rem;
        background-color: rgba(16, 16, 16, 0.493);
        /* border: 0.1px solid rgba(255, 255, 255, 0.2); */
        border: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .topnav {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border: none;
        border-bottom: 0.1px solid rgba(255, 255, 255, 0.2);

        justify-content: center;
    }

    .logo {
        width: auto;
    }


    .logo {
        height: 3rem;
        display: flex;
        align-items: center;
    }

    .logotext {
        margin: 0 1rem;
        font-size: x-large;
        font-weight: bold;
        color: white;
    }

}